@font-face {
  font-family: 'Segoe UI';
  src: local('Segoe UI'), url(./assets/fonts/Segoe-UI.woff) format('woff');
}

@font-face {
  font-family: 'Segoe-Regular';
  src: local('Segoe'), url(./assets/fonts/Segoe-UI.woff) format('woff');
}

@font-face {
  font-family: 'Segoe-Bold';
  src: local('Segoe'), url(./assets/fonts/Segoe-UI-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Segoe-Semibold';
  src: local('Segoe'), url(./assets/fonts/Segoe-UI-Semibold.ttf) format('truetype');
}

@font-face {
  font-family: 'Segoe-Bold-Italic';
  src: local('Segoe'), url(./assets/fonts/Segoe-UI-Bold-Italic.woff) format('woff');
}

@font-face {
  font-family: 'Segoe-Italic';
  src: local('Segoe'), url(./assets/fonts/Segoe-UI-Italic.woff) format('woff');
}

@font-face {
  font-family: 'SF UI Display Ultralight';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Ultralight'),
    url('./assets/fonts/sf-ui-display-ultralight-58646b19bf205.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Thin';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Thin'), url('./assets/fonts/sf-ui-display-thin-58646e9b26e8b.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Light';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Light'), url('./assets/fonts/sf-ui-display-light-58646b33e0551.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Medium';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Medium'), url('./assets/fonts/sf-ui-display-medium-58646be638f96.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Semibold'), url('./assets/fonts/sf-ui-display-semibold-58646eddcae92.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Bold';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Bold'), url('./assets/fonts/sf-ui-display-bold-58646a511e3d9.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Heavy'), url('./assets/fonts/sf-ui-display-heavy-586470160b9e5.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Black';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Black'), url('./assets/fonts/sf-ui-display-black-58646a6b80d5a.woff') format('woff');
}

/* FONT DECLARATION : 'Inter' */
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Thin.ttf');
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-ExtraLight.ttf');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Light.ttf');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Medium.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Bold.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-ExtraBold.ttf');
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Black.ttf');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

/* FONT DECLARATION : 'Poppins' */
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Thin.ttf');
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraLight.ttf');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Light.ttf');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Medium.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Bold.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraBold.ttf');
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Black.ttf');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

body {
  font-family: 'Poppins';
  background-color: #000;
  color: #fff;
}

.rc-slider-handle :hover {
  background-color: rgb(209, 209, 209);
  border-color: blueviolet;
  height: 100px;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: green;
  box-shadow: none;
}

.react-datetime-picker__wrapper {
  border: none;
  color: black;
}

.react-datetime-picker__inputGroup__input,
.react-datetime-picker__button svg {
  color: black;
  stroke: black;
}

.react-datepicker__month-container,
.react-datepicker__time-box {
  background-color: #f2f4f7;
  color: black !important;
}

.react-datepicker__header {
  text-align: center;
  background-color: white;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  border-bottom: 1px solid #444;
  position: relative;
  color: black;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: black !important;
}

.react-datepicker__day--today,
.react-datepicker__day--today:hover {
  background-color: white;
  color: black !important;
  border-radius: 0.3em;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: #e04f16;
  color: white !important;
}

.autocomplete-dropdown-container {
  background-color: #1a1a1a;
  border-radius: 5px;
  color: #fff;
  position: absolute;
  z-index: 1;
}

@media (max-width: 1000px) {
  body {
    min-height: -moz-available;
    min-height: -webkit-fill-available;
  }
  html {
    height: -moz-available;
    height: -webkit-fill-available;
  }
}
