body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  background-color: black;
}

body {
  overscroll-behavior: contain;
  overflow: auto;
}

body::-webkit-scrollbar-track {
  border-radius: 10px;
}

body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: black;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ffffffe3;
}

a {
  text-decoration: none;
  color: inherit;
}

button,
input,
select,
textarea {
  font-family: inherit;
}
